@import "variables.less";

.table {
    &-permission {
        tbody > tr:nth-of-type(odd) {
            background-color: @table-bg-accent;
        }

        th, td {
            font-size: 14px;
            padding: 0 24px;
            height: 48px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: rgb(128, 132, 141);
            position: relative;
            background-color: inherit;
            text-transform: uppercase;
        }

        th {
            font-weight: bold;
        }
    }
    &-row-bordered {
        border: 1px solid @table-border-color;
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    border-top: 1px solid @table-border-color;
                }
            }
        }
    }
}

.Component-horizontalScrollContainer-12 {
    background: #f1f1f1;
}

.MuiTable-root:first-child {
    background: white;
    border-collapse: collapse;
    animation: float 5s infinite;
    white-space: nowrap;

    th {
        color:rgba(0,0,0,.54);
        background: linear-gradient(180deg,#e7e7e7 0,#d1d1d1);
        //border-bottom:4px solid #ADD2FF;
        border-top: 1px solid #b1b1b1;
        border-right: 1px solid #C1C3D1;
        padding:12px;
        text-align:center;
        vertical-align:middle;
        white-space: nowrap;
    }

    th:first-child {
        //border-top-left-radius:3px;
    }

    th:last-child {
        //border-top-right-radius:3px;
        border-right:none;
    }

    tr {
        //border-top: 1px solid #C1C3D1;
        //border-bottom: 1px solid #C1C3D1;
        color:#666B85;
    }

    tr:hover td {
        //background:#b1b1b1;
        //color:#FFFFFF !important;
        //border-top: 1px solid #22262e;
    }

    tr:first-child {
        border-top:none;
    }

    tr:last-child {
        border-bottom:none;
    }

    tr:nth-child(even) td {
        background:#EBEBEB;
    }

    tr:nth-child(even):hover td {
        //background:#b1b1b1;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius:3px;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius:3px;
    }

    td {
        background:#FFFFFF;
        text-align:left;
        vertical-align:middle;
        border-right: 1px solid #C1C3D1;
    }

    td:last-child {
        border-right: 0px;
    }

    th.text-left {
        text-align: left;
    }

    th.text-center {
        text-align: center;
    }

    th.text-right {
        text-align: right;
    }

    td.text-left {
        text-align: left;
    }

    td.text-center {
        text-align: center;
    }

    td.text-right {
        text-align: right;
    }

}