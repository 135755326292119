.user-list-toolbar {
    .country-select {
        margin: 0 10px;
        width: 150px;
        .country-select-field {
            color: white;
            padding-bottom: 2px;
            font-size: 15px;
            line-height: 24px;
            border-bottom: solid 1px white;
            .placeholder {
                color: white;
            }
        }
    }
}
