.loading-indicator {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    &.page-centered {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__text {
        padding: 10px;
        text-align: center;
    }
}

.st0{fill:none;stroke:#e55a00;stroke-width:5.6943;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st1{fill:#ff8333;}
.st2{fill:none;stroke:#e55a00;stroke-width:5.8983;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st3{fill:#e55a00;}
.st4{fill:#ff8434;}
.st5{fill:none;stroke:#e55a00;stroke-width:7.5924;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st6{fill:#FFFFFF;}
.st7{fill:none;stroke:#7691BA;stroke-width:13.2866;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st8{fill:none;stroke:#ff8434;stroke-width:4.4082;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st9{fill:none;stroke:#fff;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

.audiosvg{
    width: 40%;
    max-width: 300px;
    max-height: 50vh;
    margin: auto;
    display: block;
}
#hover-circle .st4{
    transition: all 0.25s;
}
#ripple-circle circle{
    opacity: 0.5;
}
.audio-overlay{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    z-index: 100;
    bottom: 0;
}

.loadingText {
    position: relative;
    right: -1px;
    top: 35px;
    span {
        //text-shadow: -1px -1px 1px #fff, 2px 2px 1px #c0c0c0;
        display: inline-block;
        font-weight: 600 !important;
        font-size: 1rem !important;
        color: @brand-primary;
        animation: flip 2s infinite;
        animation-delay: calc(.3s * var(--i))
    }

}

@keyframes flip {
    0%,80% {
        transform: rotateY(360deg)
    }
}

@media screen and (max-width: 768px) {
    .js-video {
        border: none;
    }
    .slideshow ul li {
        padding: 0;
    }
    .info-container p {
        text-align: center;
    }
    .video-container{
        padding: 20px 20px 8vh 20px;
    }
    .audio-container{
        padding: 8vh 20px 20px 20px;
    }
}
@media screen and (max-width: 480px) {
    .circle {
        width: 4px;
        height: 4px;
        margin: 2px 4px;
    }
    #videosvg, .audiosvg {
        width: 66%;
    }
    .dialog__content {
        width: 80% !important;
        padding: 10px !important;
    }
    .dialog__content button {
        border-radius: 0 !important;
        height: 30px !important;
    }
    .qingli-logo {
        width: 36px;
        height: 36px;
        top: 15px;
        right: -10px;
    }
}