.games-navbar {
    font-size: 13px;
    .navbar-form {
        margin: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    .container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &-state {
            display: flex;
            justify-content: left;
            flex: 1 1 auto;
            div {
                padding-right: 1rem;
            }
        }
    }
}
