@import "variables";

.product-tile {
    font-weight: 300;
    cursor: pointer;
    text-align: center;
    min-height: 315px;
    padding-right: 10px;
    padding-left: 10px;
    flex: 1 1 340px;
    max-width: 340px;
    &.readonly {
        opacity: 0.5;
    }

    > .inner {
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
        margin-bottom: 30px;
        background: rgba(255, 255, 255, 0.8);
        position: relative;
        margin-top: 55px;
        border: 1px solid rgb(226, 224, 223);
        padding: 100px 15px 20px;

        > .flags {
            line-height: 0;
            min-height: 16px;
        }

        > .cover {
            width: 126px;
            height: 144px;
            position: absolute;
            margin: 0 auto;
            top: -32px;
            left: 50%;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            transform: translate(-50%, 0);
            background: transparent;
            .cover-image {
                max-width: 126px;
                max-height: 144px;
                flex: 0 1 auto;
            }
        }

        > .counts {
            text-align: left;
            display: inline-flex;
            align-items: center;
            padding: 5px;
            width: 100%;
            justify-content: center;
            height: 33px;
            &.request {
                background-color: @request-color;
                color: white;
            }
            .count-label {
                margin-right: 5px;
            }
            .count {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        > .title {
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
            height: 52px;
            margin-bottom: 4px;
            margin-top: 17px;
            overflow: hidden;
        }

        > .data {
            text-align: left;
            table {
                background: none;
                margin: 0 auto;
                td {
                    background: none;
                }
                th {
                    padding-right: 10px;
                    font-weight: normal;
                }
            }
        }
    }
}
