@import 'variables.less';

.review-square {
    height: 10px;
    width: 10px;
    background-color: gray;
    &--sync, &--ready {
        background-color: @info-color5;
    }
    &--request {
        background-color: @info-color3;
    }
    &--review {
        background-color: @info-color7;
    }
    &--text {
        height: auto;
        width: auto;
        padding: 4px;
    }
}
