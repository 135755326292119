.button-bar {
    border-top: 2px solid #f5f5f5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 2rem;
    > * {
        margin: 0 4px;

        &:last-child {
            margin-right: 0;
        }
    }

    &.with-country {
        justify-content: space-between;
    }
}
