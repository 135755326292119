.company-meta-form {
    display: flex;
    max-width: 600px;
    margin: 20px auto;
    .form-group {
        flex: 1;
        margin: 0;
        &:nth-child(2) {
            flex: 0 1 auto;
            padding-left: 10px;
        }
    }
}
