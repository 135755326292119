.review-lights {
    display: inline-flex;
    color: black;
    font-size: 11px;
    .review-square {
        height: 16px;
        width: 16px;
    }
    .review-square--text {
        height: auto;
        width: auto;
        color: white;

    }
    .number {
        line-height: 1;
        background: rgb(224, 224, 224);
        padding: 1px;
        min-width: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
