@import 'variables';

.article-list {
    width: 100%;

    .article-group-panel {
        .article-country {
            flex: 1;
        }
    }

    .article-card {
        width: 100%;
        margin-bottom: 5px;

        .form-headline {
            margin-top: 0;
            margin-bottom: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 5px;
        }

        &.expanded {
            .card-header {
                position: sticky !important;
                top: 0;
                background: white;
                z-index: 1000000000;
                box-shadow: 0 2px 2px -1px rgba(0,0,0, 0.17);
            }
        }
        &.readonly {
            opacity: 0.5;
            &.expanded {
                opacity: 1;
            }
        }

        .article-cover {
            position: relative;
            .icon-button {
                opacity: 0;
                &:hover {
                    opacity: 1;
                }
                svg {
                    left: 50%;
                    transform: translate(-50%, 0);
                    position: absolute;
                }
            }
        }

        .header-row {
            display: flex;
            flex-direction: row;
            &--review {
                justify-content: flex-end;
                .header-text {
                    flex: 0.5 1 100%;
                }
            }
        }
        .header-col {
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 3px;

            .header-label {
                color: #8E8581;
                font-size: 14px;
                padding: 3px 6px;
                background-color: #F3F2F2;
                font-weight: 300;
                min-width: 130px;
                &.header-label-small {
                    min-width: 100px;
                }
            }
            .header-text {
                color: #8E8581;
                font-size: 14px;
                font-weight: bold;
                max-width: 125px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            img {
                height: 20px;
            }

            &--left {
                align-items: flex-start;
            }
            &.header-col-status {
                min-width: 130px;
                .header-row {
                    width: 100%;
                }
                .header-label {
                    font-size: 12px;
                    background: none;
                    min-width: unset;
                    padding: 2px 4px;
                    flex: 1 1 auto;
                }
                .header-options {
                    justify-content: space-around;
                    margin-top: 10px;
                }
                .header-row--review {
                    justify-content: flex-end;
                }
                .header-text {
                    font-size: 12px;
                    max-width: none;
                    color: white;
                    flex: 0.5 1 auto;
                    justify-content: center;
                    padding: 2px 8px;
                    &.new {
                        background: @brand-primary;
                    }
                    &.sync, &.ready {
                        background: @info-color5;
                    }
                    &.request {
                        background: @info-color3;
                    }
                    &.review {
                        background: @info-color7;
                    }
                }
            }
        }

    }
}
