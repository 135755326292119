@import "variables.less";

.article-filter-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;+


    .text {
        font-size: 16px;
        color: @headline-color;
    }

    > * {
        margin-right: 10px;
    }
}
