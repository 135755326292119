@import "variables.less";

.footer {
    height: 50px;
    position: fixed;
    bottom: 0;
    width: 100%;
    transition: height 0.4s linear;
    background: @navbar-page-bg;
    box-shadow: 0 -2px 6px 0 #D4D4D4;
    z-index: 1000;
    display: flex;

    @media (max-width: @screen-xs-max) {
        height: 30px;
    }

    .container {
        display: flex;
        .footer--left {
            flex: 1 1 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .logo {
                vertical-align: middle;
                height: 40px;
                display: inline-block;
                @media (max-width: @screen-xs-max) {
                    height: 20px;
                }
            }

            a {
                margin-left: 10px;
            }
        }

        .footer--right {
            flex: 1 1 50%;
            justify-content: flex-end;
            align-items: center;
            display: flex;
            .version {
                margin-right: 10px;
            }
            .copyright {
                vertical-align: middle;
                display: inline-block;
                font-size: 12px;
                line-height: 16px;
                font-weight: bold;
                color: rgb(142, 133, 129);
                @media (max-width: @screen-xs-max) {
                    line-height: 1;
                    font-size: 10px;
                }
            }
        }
    }
}
