.signin {
    display: flex;
    align-items: center;
    padding: 0;
    //@media (max-width: @screen-xs-min) {
    //    padding: 0;
    //    max-width: 760px;
    //    width: 100%;
    //}

    .MuiPaper-root {
        padding: 20px;
        width: 100%;
    }

    .MuiAlert-standardError,
    .MuiFormControl-root {
        margin-bottom: 1rem;
    }

    .MuiFormHelperText-root {
        color: red;
    }

    .form-group {
        div {
            width: 100%;
        }
        min-width: 400px;
        //@media (max-width: @screen-xs-min) {
        //    width: 100%;
        //    min-width: 0;
        //}
    }

    .signin-form {
        margin: -15px 30px 0 30px;
        .MuiFormControl-root {
            margin: 0;
            height: 70px;
        }
        .btnPasswordForgotten {
            font-size: .75rem;
            padding: 0;
        }
    }
}

.music-player-container {
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 370px;
    position: absolute;
    min-width: 460px;
    left: 45%;
    top: 50%;

    &:after {
        -webkit-filter: blur(8px);
        filter: blur(8px);
        background-color: rgba(0, 0, 0, 0.8);
        bottom: -2px;
        content: ' ';
        display: block;
        height: 10px;
        left: 19px;
        position: absolute;
        transform: rotate(-3deg);
        width: 55%;
        z-index: 0;
    }

    .music-player {
        background-color: #fff;
        height: 370px;
        padding: 40px 145px 40px 40px;
        position: absolute;
        text-align: right;
        width: 370px;
        z-index: 3;
        .player-content-container {
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            top: 50%;
            position: relative;
            .MuiTypography-body1 {
                font-size: 1.4rem;
                font-width: 700;
            }
        }
        //color: #dedede;
        //text-decoration: none;
        //text-shadow: 1px 1px 0 #ffffff, -1px -1px 0 #a1a1a1;
        //background:
        //        -moz-linear-gradient(
        //                #ffffff,
        //                #e3e3e3
        //        );
        //background:
        //        -webkit-linear-gradient(
        //                #ffffff,
        //                #e3e3e3
        //        );
        //background:
        //        -o-linear-gradient(
        //                #ffffff,
        //                #e3e3e3
        //        );
        //background:
        //        linear-gradient(
        //                #ffffff,
        //                #e3e3e3
        //        );
        .MuiTypography-root {
            //font-family: "roboto_condensedbold";
            font-size: 1.4rem;
            font-weight: 700;
            color: transparent;
            background: @brand-primary;
            -webkit-background-clip: text;
            text-shadow: 0px 3px 3px rgba(255,255,255,0.5);
        }
    }
    .album {
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.65);
        height: 315px;
        margin-left: 250px;
        margin-top: 27px;
        position: relative;
        width: 351px;
        z-index: 10;
        border-radius: 5px;

        .album-art {
            //background: #fff url("../img/bg.jpg") center/cover no-repeat;
            background-color: white;
            height: 315px;
            position: relative;
            width: 351px;
            z-index: 10;
            text-align: right;
            border-radius: 5px;
        }

        .vinyl {
            -webkit-animation: spin 2s linear infinite;
            -moz-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
            -webkit-transition: all 500ms;
            -moz-transition: all 500ms;
            transition: all 500ms;
            //background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/83141/vinyl.png"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/83141/incubus-make-yourself.jpg");
            //background-position: center, center;
            //background-size: cover, 40% auto;
            background-image: url("../img/cd.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 100%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
            height: 295px;
            left: 6px;
            position: absolute;
            top: 10px;
            width: 295px;
            z-index: 5;
            will-change: transform, left;
        }
    }
    &.is-playing {
        .vinyl {
            left: 58%;
        }
    }
}






.artist-name {
    font-size: 28px;
    font-weight: normal;
    margin: 0 0 0.75em 0;
}

.album-title {
    font-weight: 200;
    font-size: 24px;
    margin: 0 0 1.75em 0;
}

.song-title {
    font-size: 18px;
    font-weight: 200;
    margin: 0 0 1.5em 0;
}

.music-player-controls {
    text-align: center;
}

[class^="control-"] {
    -webkit-filter: brightness(95%);
    filter: brightness(95%);
    border-radius: 100%;
    display: inline-block;
    height: 44px;
    margin: 0 3px;
    width: 44px;
}
[class^="control-"]:hover {
    -webkit-filter: brightness(85%);
    filter: brightness(85%);
    cursor: pointer;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cd-art {
    border: 5px solid @brand-primary;
    height: 305px;
    width: 305px;
    border-radius: 3px;
    background: white;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.5),inset 0px 0px 16px 2px rgba(0,0,0,0.1), inset 0px 0px 0px 1px rgba(255,255,255,0.3);
    position: relative;
    top: 5px;
    left: 38px;
    //z-index: -2;
}
.cd-case {
    height: 315px;
    border-radius: 5px;
    background-image: linear-gradient(135deg, #e55a00, #e97030, #ec8652, #ed9a71, #ebae91);
}

.sup {
    height: 15px;
    width: 30px;
    background: rgba(255,255,255,0.15);
    box-shadow: inset 0px 0px 6px 1px rgba(0,0,0,0.22), inset 0px 0px 1px 1px rgba(255,255,255,0.17);
}
.pos-tl {
    position: relative;
    top: -5px;
    left: 30px;
    border-radius: 0 0 5px 5px;
}
.pos-tr {
    position: relative;
    top: -20px;
    left: 245px;
    border-radius: 0 0 5px 5px;
}
.pos-bl {
    position: relative;
    top: 255px;
    left: 30px;
    border-radius: 5px 5px 0 0;
}
.pos-br {
    position: relative;
    top: 240px;
    left: 245px;
    border-radius: 5px 5px 0 0;
}
.spine {
    height: 183px;
    width: 20px;
    background: -webkit-linear-gradient(rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.07) 100%);
    box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.23), inset 0px 0px 7px 0px rgba(0,0,0,0.22), inset 0px 0px 0px 1px rgba(255,255,255,0.22);
    position: relative;
    top: -245px;
    left: 0;
    border-radius: 0 50% 50% 0;
    //z-index: -1;
}