.help {
    text-transform: none;
    padding-bottom: 100px;
    p {
        font-size: 18px;
        line-height: 1.2;
    }

    .faq-index {
        li {
            font-size: 16px;
        }
    }
    .faq-anwsers {
        margin-top: 20px;
    }
}
