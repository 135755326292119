@import "variables";

.product-navbar {
    box-shadow: 0 3px 8px rgb(0 0 0 / 32%);
    flex: 0 0 3rem;
    z-index: 1000;
    //top: 40px;
    height: 70px;
    //position: fixed;
    width: 100%;
    transition: top 0.4s linear;
    background-color: #ffc097;
    background-image: linear-gradient(178deg,#f9935f,#e55a00 74%);
    .container {
        display: flex;
        align-items: center;
        align-content: center;
        height: 100%;
    }
    .navbar-logo {
        flex: 0 1 auto;
        cursor: pointer;
    }

    .product-search {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 3%;
        input {
            flex: 1;
            height: 34px;
            padding: 0 2%;
            box-sizing: border-box;
            border: 1px solid white;
            outline: none;
        }
    }
    .menu-link {
        color: white;
        height: 100%;
        flex: 0;
        border-left: 1px solid white;
        border-right: 1px solid white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: @navbar-menu-selected-bg;
        a {
            padding: 0 10px;
            color: white;
            font-size: 18px;
            text-decoration: none;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        span {
            padding: 0 10px;
            color: white;
            font-size: 18px;
        }

    }
    .menu-account {
        color: white;
        height: 100%;
        line-height: 46px;
        border-left: 1px solid white;
        padding-left: 1rem;
        button {
            font-size: .85rem;
        }
    }
}
