.cover-input {
    display: flex;

    .cover-input-button {
        width: 50px;
        height: 66px;
        display: flex;
        margin-top: 0;
        text-align: center;
        position: relative;
        background-color: rgb(245, 246, 246);
        border-bottom: 3px solid rgb(227, 224, 224);
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            max-width: 100%;
            max-height: 100%;
        }
        input[type="file"] {
            position: absolute;
            opacity: 0;
            z-index: 0;
            max-width: 100%;
            height: 100%;
            display: block;
            cursor: pointer;
        }
    }
    label {
        margin: 0 0 0 15px;
        .info {
            font-size: 18px;
            font-weight: 300;
            line-height: 24px;
        }
        .sub-info {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            margin-top: 13px;
        }

        .sub-sub-info {
            font-size: 10px;
            line-height: 13px;
        }
    }
}
