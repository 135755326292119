@import "variables.less";

.country-select {
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .flag-tag {
        display: inline-block;
        margin-right: 4px;
        font-size: 14px;
        line-height: 16px;
        &--disabled {
            opacity: 0.5;
        }
    }
    &:focus {
        .country-select-field {
            border-bottom: 2px solid @brand-primary;
        }
    }
    .country-select-field {
        padding: 5px;
        line-height: 20px;
        border-bottom: 2px solid rgb(224, 224, 224);

        .placeholder {
            color: rgb(172, 172, 172);
        }
    }
}

.country-select-dialog {
    .modal-body {
        display: flex;
        flex-wrap: wrap;
    }
    .country-tile-container {
        display: flex;
        flex-wrap: wrap;

        .country-tile {
            cursor: pointer;
            margin: 0;
            padding: 8px 16px;
            width: 60px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 0 1 60px;
            max-width: 60px;
            justify-content: center;
            background: white;

            &:nth-child(odd) {
                background: #f1f1f1;
            }

            &--selected.country-tile {
                background: rgba(255, 165, 0, 0.50);
            }

            &--disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}
