@import "variables";

.page-nav {
    height: 39px;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #ffffff;
    z-index: 5;
    transition: top 0.4s linear;

    .login-button {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}
