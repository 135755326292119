// Core variables and mixins
@import "variables";
// Reset and dependencies
@import "normalize";
@import "print";
// Core CSS
@import "mui";
// Components
@import "grid";
@import "tables";
// Utility classes
@import "footer";
@import "games-navbar";
@import "product-tile";
@import "country-select";
@import "product-navbar";
@import "page-navbar";
@import "icon-label";
@import "gmr";
@import "article-list";
@import "article-filter-bar";
@import "cover-input";
@import "loading-inidicator";
@import "signin";
@import "password-forgotten";
@import "signup";
@import "table";
@import "product-list";
@import "review-square";
@import "button-bar";
@import "user-list-toolbar";
@import "review-lights";
@import "company-form";
@import "companies-table";
@import "help";
@import "admin-user";
@import "admin-user-list";
@import "flags-bar";

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

// Links
a {
    color: @link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: @link-hover-color;
        text-decoration: @link-hover-decoration;
    }

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

html, body {
    font-size: 16px;
    font-family: @font-family-sans-serif;
    height: 100%;
    width: 100%;
}

body {
    background: rgb(245, 245, 245);
    color: rgb(128, 132, 141);
    text-transform: uppercase;
    padding: 0 !important;
    overflow: auto !important;
}

input, textarea, select, button {
    text-transform: inherit;
}

hr {
    margin: 5px auto;
    border-bottom: 1px solid rgba(142, 133, 129, 0.4);
    border-top: none;

    border-right: none;
    border-left: none;
}

h1, h2, h3 {
    color: @headline-color;
}

h1 {
    font-size: 26px;
    font-weight: 300;
}

h2 {
    font-size: 22px;
    font-weight: bold;
}

main {
    height: 100%;
    width: 100%;
    justify-content: center;

}

section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.spinning {
    animation: spin 1s infinite;
}

.hide {
    display: none !important;
}

.fullwidth {
    width: 100%;
}

.loginSection {
    background: #D1913C;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFD194, #D1913C);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFD194, #D1913C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    .signin {
        background-color: rgba(0,0,0,0) !important;
        a {
            &.MuiButton-root:hover {
                color: white;
            }
        }
    }
}

@font-face {
    font-family: 'roboto_condensedregular';
    src: url('../fonts/RobotoCondensed-Regular-webfont.woff') format('woff'),
    url('../fonts/RobotoCondensed-Regular-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'roboto_condensedbold';
    src: url('../fonts/roboto-condensed-v25-latin-700.woff') format('woff'),
    url('../fonts/roboto-condensed-v25-latin-700.ttf') format('truetype');
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

@-webkit-keyframes spin2 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.badge-greater {
    font-size: 18px;
    font-weight: 300;
}

.btn:focus {
    outline: none;
}

.btn {
    font-size: @btn-font-size;
}

.font-style-1 {
    font-size: 22px;
    font-weight: 300;
}

.hint {
    font-size: 12px;
    font-weight: 300;
}

.center-box {
    max-width: 600px;
    margin: 0 auto;
    padding: 15px 40px;
}

.golden-ratio-md {
    width: auto;

    @media (min-width: @grid-float-breakpoint) {
        width: 66%;
    }
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: @screen-xs-max) {
        width: 100%;
    }
}

.save-btn {
    background-color: green;
}

.review-ok-btn {
    background-color: green;
}

.save-not-ok-btn {
    background-color: red;
}

.flex {
    display: flex;
    flex: 1;
}

.top-buffer {
    margin-top: 15px;
}

.no-margin {
    margin: 0 !important;
}

.no-background {
    background: none;
}

.no-border {
    border: none;
}

.no-bold {
    font-weight: normal;
}

.no-zindex {
    z-index: 0;
}

.bold {
    font-weight: bold;
}

.vertical-padding {
    padding-top: 15px;
    padding-bottom: 15px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.clickable {
    cursor: pointer;
}


form {
    .sub-row-field {
        &::after {
            clear: both;
        }
        &--extended {
            .field-info-text {
                font-size: 12px;
                line-height: 15px;
                padding: 2px;
                color: @text-color;
            }
        }
        &.no-height {
            height: auto;
        }
        &.with-toggle {
            display: flex;
            align-items: center;
            padding-left: 3px;
            background: #F5F6F6;
        }
    }

    .form-group.form-error {
        .field-info-text {
            display: none;
        }
    }

    .text-profile {
        color: rgb(113, 97, 98);
        height: 56px;
        font-size: 18px;
        line-height: 56px;
        overflow: hidden;
        opacity: 1;
        position: relative;
        padding-left: 0px;
        padding-right: 56px;
        text-overflow: ellipsis;
        top: -12px;
        white-space: nowrap;
        cursor: text;
    }
}

@media (max-width: 1000px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
}

//
// Site specific rules
//
.page {
    flex: 1 1 auto;
    min-height: 1px;
    //padding-top: 50px;
    //background: rgba(245, 245, 245, 0.8);
    //min-height: 96.2vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &.content {
        background: #ffffff;
    }
    .content {
        flex: 1;
        display: flex;
        width: 100%;
        > .headline {
            margin-bottom: 2rem;
        }
    }
    .content--column {
        flex-direction: column;
        padding-bottom: 30px;
    }

    &.products {
        width: 100%;
        height: 100%;
        padding: 0px !important;
        flex: 1 1 auto;
        min-height: 1px;
    }
}

.search-container,
.products-container {
    background: url('../img/background-1.jpg');
    background-size: cover;
}

.with-product-navbar .page {
    padding-top: 130px;
}

.admin-container,
.with-product-navbar.with-games-navbar,
.profile-container,
.password-change-container,
.permissions-container {
    .page {
        padding-top: 180px;
        &.signin {
            padding-top: 0px
        }

    }
}

.page-nav {
    top: 0;
    z-index: 5;

    .user-avatar {
        height: 20px;
        width: 20px;
        margin-right: 3px;
    }
}

.product-list {
    height: 100%;
    width: 100%;
    .MuiDataGrid-root {
        border-top: 1px solid #b1b1b1;
        .MuiDataGrid-columnHeader {
            &:first-child {
                border-left: 0 !important;
            }
            &:last-child {
                border-right: 0 !important;
            }
            background: linear-gradient(180deg,#e7e7e7 0,#d1d1d1);
            border-bottom-width: 0!important;
            border-color: #fff #fff #b1b1b1 #b1b1b1 !important;
            border-style: solid;
            border-width: 1px;
            .MuiDataGrid-iconButtonContainer {
                .MuiSvgIcon-root {
                    color: @brand-primary;
                }
            }
            .MuiDataGrid-menuIcon {
                margin-right: -5px;
                .MuiSvgIcon-root {
                    color: @brand-primary;
                }
            }
            .MuiDataGrid-columnSeparator  {
                display: none;
            }
        }
        .MuiDataGrid-row {
            background-color: white;
            &:hover {
                background-color: lighten(#008ae4, 40%) !important;
            }
            &.odd {
                background-color: #EBEBEB;
            }
            &.Mui-selected {
                background-color: #ffefe5 !important;
            }
            .MuiDataGrid-cell:first-child {
                border-left: 0 !important;
                text-align: center;
            }
            .MuiDataGrid-cell:last-child {
                border-right: 0 !important;
            }
        }
        .MuiDataGrid-columnHeader,
        .MuiDataGrid-cell {
            padding: 0 .4rem;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgba(0,0,0,.12);
            border-color: #fff #fff #b1b1b1 #b1b1b1;
            border-style: solid;
            border-width: 1px;
            &:focus,
            &:focus-within {
                outline-width: 0 !important;
            }
            .MuiDataGrid-actionsCell {
                grid-gap: 5px;
                .MuiIconButton-root {
                    color: @request-color;
                    .MuiSvgIcon-root {
                        font-size: 1.5rem;
                    }
                }
            }
            .MuiChip-root {
                width: 65px;
            }
            .MuiCheckbox-root {
                &.Mui-disabled {
                    display: none;
                }
            }
        }
    }

    .actionSelectedItems {
        display: flex;
        position: absolute;
        z-index: 20;
        bottom: 8px;
        left: 20px;
        .backup {
            box-shadow: 0px 1px 3px rgba(0,0,0,.8);
            background-color: @brand-primary;
            color: white;
            margin-right: 1.5rem;
            &:hover {
                background-color: @ready-color;
            }
            &.delete {
                &:hover {
                    background-color: @request-color;
                }
            }
            .MuiBadge-standard {
                top: -2px;
                right: -2px;
                background-color: @review-color;
            }
        }
    }

    .MuiPaper-root {
        div:first-child {
            //height: 100%;
            div {
                height: 100%;
            }
        }
    }
}

.upload-list {
    height: 100%;
    width: 100%;
    .MuiPaper-root {
        > div:first-child {
            text-align: center;
            button {
                margin: 1rem;
            }
        }
        div:nth-child(2) {
            height: 100%;
            div {
                height: 100%;
            }
        }
    }
}

form {
    .row {
        margin-left: 0;
        margin-right: 0;

        .row {
            margin: 0;
        }
    }
}

.product-form, .international-meta-form {
    .MuiButton-root {
        margin-left: 1rem;
        &.review {
            background-color: @review-color;
        }

        &.ready {
            background-color: @ready-color;
        }
        &.request {
            background-color: @request-color;
        }
    }

    .MuiChip-root {
        color: #FFF;
        border-radius: 0px;
        margin-left: 1rem;
    }

    .form-group {
        padding-left: 5px;
        padding-right: 5px;
    }
    .cover-input, .upload-notice {
        padding: 0 5px 0 0;
    }
    .cover-input {
        .cover-input-button {
            height: 114px;
            width: 100%;
            .dropzone {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .form-error .cover-input .cover-input-button {
        border-bottom: 3px solid @form-label-error-bg;
    }

    .upload-notice {
        .note-header {
            height: 30px;
            padding: 5px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            line-height: 19px;
            background-color: #AAA3A0;
            margin-bottom: 22px;
        }
        font-size: 12px;
        line-height: 15px;
    }
}

.international-meta-form {
    padding: 2rem 2rem 2rem 2rem;
    .cover-input, .upload-notice {
        padding: 0;
    }
    .cover-input {
        .cover-input-button {
            height: 271px;
        }
    }
}

.profile-container {
    .profile {
        .form-divider {
            margin-top: 40px;
        }
    }
}

.upload {
    display: flex;
    .upload-button {
        width: 50px;
        height: 66px;
        display: flex;
        text-align: center;
        background-color: #F3F2F2;
        border: 1px solid #D4D0CF;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            max-width: 100%;
            max-height: 100%;
        }
        input[type="file"] {
            position: absolute;
            opacity: 0;
            z-index: 0;
            max-width: 100%;
            height: 100%;
            display: block;
            cursor: pointer;
        }
    }
    label {
        margin: 0 0 0 15px;
        .info {
            font-size: 18px;
            font-weight: 300;
            line-height: 24px;
        }
        .sub-info {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            margin-top: 13px;
        }

        .sub-sub-info {
            font-size: 10px;
            line-height: 13px;
        }
    }
}
.dropzone-ui {
    font-family: roboto_condensedregular !important;
    font-size: .85rem !important;

    &.dropzone-ui-extra {
        border-color: @brand-primary !important;
    }

    .dz-ui-label,
    .dz-ui-header,
    .file-item-name,
    .file-status {
        font-family: roboto_condensedregular !important;
    }

    .file-item-name {
        font-size: .75rem !important;
    }

    .dz-ui-label {
        color: @brand-primary !important;
    }

    .dz-ui-header {
        height: 30px !important;
        top: 8px !important;
        span {
            line-height: 2rem;
            svg {
                width: 28px;
                height: 28px;
                fill: @brand-primary;
            }
        }
    }
}

.games-navbar {
    flex: 0 0 3.25rem;
    z-index: 3;
    //padding-top: 0;
    //padding-bottom: 5px;
    //position: fixed;
    width: 100%;
    //top: 110px;
    //height: 56px;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(212, 212, 212, 0.5);
    transition: top 0.5s linear;
}

.admin-navbar, .profile-navbar {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
}

.publisher-permissions-form {
    > * {
        margin-right: 10px;
    }
}
.form-error {
    label.icon-label {
        color: white;
        background: @form-label-error-bg;
        .required-postfix {
            color: white;
        }
        .icon {
            background: @form-icon-error-bg;
        }
    }
}
.form-group {

    .sub-row-label {
        border-bottom: 2px solid white;
    }
    min-height: 75px;
}

.form-row {
    .rowspan {
        padding: 0 5px;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }

    .form-group {
        &:first-child {
            // padding-left: 0;
        }
        &:last-child {
            // padding-right: 0;
        }
    }
}

.search-container,
.help-container,
.history-container,
.products-container {
    .page {
        justify-content: flex-start;
    }
    .content, .product-tiles {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.password-reset {
    display: flex;
    align-items: center;
    @media ( max-width: @screen-xs-min) {
        padding: 0;
    }
    .panel {
        margin: 50px auto;
        .panel-body {
            padding: 20px;
            @media ( max-width: @screen-xs-min) {
                padding: 15px;
            }
        }
    }
    .form-group {
        min-width: 200px;
        @media (min-width: @screen-sm-min) {
            min-width: 300px;
        }
        @media (min-width: @screen-md-min) {
            min-width: 400px;
        }
    }
}

.signin-container, .signup-container {
    &.scroll-resolver {
        padding-bottom: 0;
    }
    .page {
        padding-bottom: 50px;
    }
}

.scroll-resolver {
    padding-bottom: 50px;
    &.scrolled {
        padding-bottom: 30px;
        .page-nav {
            top: -40px;
            transition: top 0.4s linear;
        }
        .product-navbar {
            top: 0;
            height: 50px;
            transition: height 0.4s linear, top 0.4s linear;
            @media (min-width: @screen-sm-min) {
                .navbar-form {
                    margin-bottom: 8px;
                    margin-top: 8px;
                }
            }

            .navbar-brand {
                &.navbar-logo {
                    padding: 8px
                }
                transition: height 0.3s linear;
                height: 50px;
            }
            .navbar-nav > li > a {
                height: 100%;
                line-height: 50px;
                padding-top: 0;
                @media (min-width: @screen-sm-min) {
                    padding-top: 0;
                }
            }
        }
        .games-navbar {
            top: 50px;
            transition: top 0.4s linear;
            .navbar-form {
                margin-top: 4px;
            }
        }
        .page .home {
            padding-top: 100px;
        }
        .footer {
            height: 30px;
            transition: height 0.4s linear;
            .logo {
                height: 20px;
            }
            .copyright {
                line-height: 1;
                font-size: 10px;
            }
        }
    }
}

.autocomplete__menu.devide-after {
    &-1 > div:nth-child(1), &-2 > div:nth-child(2), &-3 > div:nth-child(3) {
        border-bottom: 2px dashed #AAA3A0;
    }
    &-1 > div:last-child, &-2 > div:last-child, &-3 > div:last-child {
        border-bottom: none;
    }
}

.noTextTransform {
    .MuiDataGrid-cellContent,
    input {
        text-transform: none !important;
    }
}
