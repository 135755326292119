.password-forgotten {
    display: flex;
    align-items: center;
    width: 600px;

    .MuiPaper-root {
        padding: 20px;
        width: 100%;

        h1 {
            color: @brand-primary;
            margin-top: 0;
        }

        .MuiButton-root {
            margin-left: 1rem;
        }

        .MuiAlert-standardError,
        .MuiFormControl-root {
            margin-bottom: 1rem;
        }

        .form-group {
            padding-left: 5px;
            padding-right: 0;
        }
    }

    @media ( max-width: @screen-xs-min) {
        padding: 0;
    }

    @media (max-width: @screen-sm-min) {
        width: 100%;
    }
}
