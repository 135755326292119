@font-face {
    font-family: 'gmr';
    src:
        url('../fonts/gmr.woff?a296c0') format('woff'),
        url('../fonts/gmr.ttf?a296c0') format('truetype'),
        url('../fonts/gmr.svg?a296c0#gmr') format('svg');
    font-weight: normal;
    font-style: normal;
}

.gmr-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'gmr' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.icon_int_genre:before {
        content: "\e900";
    }
    &.icon_add_team:before {
        content: "\e901";
    }
    &.icon_add_article:before {
        content: "\e902";
    }
    &.icon_age_rating:before {
        content: "\e903";
    }
    &.icon_franchise:before {
        content: "\e904";
    }
    &.icon_country:before {
        content: "\e904";
    }
    &.icon_distributor:before {
        content: "\e905";
    }
    &.icon_ean:before {
        content: "\e906";
    }
    &.icon_first_release:before {
        content: "\e907";
    }
    &.icon_genre_national:before {
        content: "\e908";
    }
    &.icon_interactive:before {
        content: "\e909";
    }
    &.icon_kids:before {
        content: "\e90a";
    }
    &.icon_label:before {
        content: "\e90b";
    }
    &.icon_language:before {
        content: "\e90c";
    }
    &.icon_media_format:before {
        content: "\e90d";
    }
    &.icon_original_master_title:before {
        content: "\e90e";
    }
    &.icon_overview:before {
        content: "\e90f";
    }
    &.icon_packaging:before {
        content: "\e910";
    }
    &.icon_price:before {
        content: "\e911";
    }
    &.icon_product_group:before {
        content: "\e912";
    }
    &.icon_publisher:before {
        content: "\e913";
    }
    &.icon_set:before {
        content: "\e914";
    }
    &.icon_system:before {
        content: "\e915";
    }
    &.icon_title_local:before {
        content: "\e916";
    }
    &.icon_view_format:before {
        content: "\e917";
    }
    &.icon_retailer_exclusive_03:before {
        content: "\e918";
    }
    &.icon_retailer_exclusive_04:before {
        content: "\e919";
    }

}
