.MuiMenuItem-root {
  font-size: .8rem !important;
}

.MuiDrawer-paper {
  padding: 2rem;
}

.MuiFormControl-root {
  &.review {
    label {
      background-color: white;
      padding-right: .5rem;
    }
    .MuiOutlinedInput-root {
      .MuiSvgIcon-root {
        right: 35px;
      }
    }
    .MuiAutocomplete-root {
      &.withreview {
        .MuiIconButton-root:last-child {
          &:hover {
            margin-right: 35px;
          }

          margin-right: 35px;
        }
      }
    }
  }

  &.request {
    label, p, svg, input, .MuiSelect-select {
      color: @request-color;
    }
    fieldset {
      border-color: @request-color;
    }
  }

  &.admin {
    label, p, svg, input, .MuiSelect-select {
      color: @review-color;
    }
    fieldset {
      border-color: @review-color;
    }
  }
}

.MuiToggleButton-root {
  &.Mui-selected {
    &.request {
      background-color: @request-color!important;
    }
    &.review {
      background-color: @review-color!important;
    }
    &.ready {
      background-color: @ready-color!important;
    }
    color: white!important;
  }
}

.MuiTable-root {
  border-collapse: inherit!important;
  .MuiTableHead-root {
    .MuiTableRow-root {
      th:first-child {
        border-left: 0 !important;
        min-width: 48px;
        //.MuiCheckbox-root {
        //  display: none;
        //}
      }
      th:last-child {
        border-right: 0 !important;
      }
      .MuiTableCell-head {
        background: linear-gradient(180deg,#e7e7e7 0,#d1d1d1);
        border-bottom-width: 0!important;
        border-color: #fff #fff #b1b1b1 #b1b1b1 !important;
        border-style: solid;
        border-width: 1px;
      }
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      td:first-child {
        border-left: 0 !important;
        text-align: center;
      }
      td:last-child {
        border-right: 0 !important;
      }
      .MuiTableCell-root {
        height: 42px !important;
        font-size: .825rem !important;
        padding: 0 .4rem;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgba(0,0,0,.12);
        border-color: #fff #fff #b1b1b1 #b1b1b1;
        border-style: solid;
        border-width: 1px;
        div {
          &.actions {
            display: flex;
            align-items: center;
            svg {
              &:first-child {
                margin-right: 5px;
              }
              cursor: pointer;
            }
          }
        }
        .material-icons,
        .MuiSvgIcon-root {
          color: @request-color;
        }
        .MuiCheckbox-root {
          .MuiSvgIcon-root {
            color: @brand-primary;
          }
        }
        .MuiTypography-root {
          font-size: .825rem;
        }
        .MuiBadge-anchorOriginTopRightRectangular {
          top: 4px;
        }
        p {
          &.MuiTypography-root {
            color: @request-color;
            &.admin {
              color: @review-color;
            }
          }
        }
        .MuiButtonBase-root {
          padding: 5px;
          &.MuiSwitch-switchBase {
            padding: 9px;
          }
          &.Mui-disabled {
            display: none;
          }
        }
        .MuiBadge-root {
          width: 100%;
          .MuiChip-root {
            width: 100%;
          }
        }

        &.css-meeyth-MuiTableCell-root {
          padding: 0 .75rem;
        }
      }
    }
  }
  .MuiTableFooter-root {
    //.MuiTablePagination-spacer {
    //  flex: 0;
    //}
    .MuiTablePagination-displayedRows {
      display: none;
    }
    .MuiTypography-caption {
      font-size: .875rem;
      color: black;
    }
  }
}

.MuiChip-root {
  font-size: .75rem;
  color: #FFF !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  &.review {
    background-color: @review-color;
  }
  &.ready {
    background-color: @ready-color;
  }
  &.request {
    background-color: @request-color;
  }
}


.user-list {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  .MuiDrawer-root {
    flex: 0 0 15rem;
    .MuiPaper-root {
      position: inherit;
    }
  }
  .cards {
    overflow: auto;
    flex: 1 1 auto;
    padding: 2rem;
    display: grid;
    //grid-auto-rows: 240px;
    grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
    grid-gap: 2rem;
    align-items: stretch;
    .MuiPaper-root {
      height: 390px;
      border-radius: 0px;
      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
      }
      .MuiCardContent-root {
        color: #000;
        position: relative;
        height: 180px !important;
        text-align: center;
        border-bottom: 1px solid #f1f1f1;
        &.active {
          color: #fff;
          background-color: #2f4353;
          background-image: linear-gradient(315deg,#2f4353,#d2ccc4 74%);
        }
        &.admin {
          color: #fff;
          background: #d1913c;
          background: linear-gradient(90deg,#ffd194,#d1913c);
        }
        div {
          height: 110px !important;
          img {
            padding-top: 0.25rem;
            width: 82px;
            height: 82px;
            border-radius: 50%;
            margin-top: 16px;
            border: 2px solid #fff;
          }
        }
      }
      .CardInfo {
        padding: 0 1rem;
        ul {
          padding: 0;
          margin: 0;
          user-select: text;
          li {
            list-style: none;
            padding: .5rem 0;
            &:not(:last-child) {
              border-bottom: 1px solid #dadada;
            }
            .MuiTypography-body1 {
              font-family: roboto_condensedbold;
              font-size: .75rem;
            }
            .MuiTypography-body2 {
              font-size: .75rem;

            }
          }
        }
      }
      .MuiCardActions-root {
        padding: 0px;
        box-shadow: 0px -3px 12px -8px rgba(0,0,0,.8);
        justify-content: space-around;
        Button {
          padding: 16px;
          color: @brand-primary;
          border-radius: 0;
          &:hover {
            box-shadow: inset 1px 2px 6px -4px rgba(0,0,0,.5);
          }
        }
      }
    }
  }
  .MuiToolbar-root {
    border-bottom: 1px solid #b1b1b1;
  }
  .MuiTableCell-root {
    &.css-meeyth-MuiTableCell-root {
      padding: 0 .70rem;
    }
  }
}

.MuiTooltip-tooltip {
  background-color: rgba(97,97,97,.95);
  ul {
    padding: 0 1rem;
    margin: .25rem 0rem;
    li {
      &.admin {
        color: lighten(@review-color, 15%);
      }
      &.ibm {
        color: lighten(@request-color, 15%);
      }
    }
  }
}

.MuiMenuItem-root {
  .MuiListItemText-root {
    .MuiTypography-root {
      font-size: .85rem;
    }
  }
}

.user-list {
  height: 100%;
  .MuiPaper-root {
    .MuiToolbar-root {
      flex: 0 0 3rem;
      min-height: 1px;
      h6 {
        padding-top: .3rem;
      }
      .MuiFormControl-root {
        .MuiInput-root {
          margin-top: 8px;
          height: 30px;
        }
      }
    }
    div:nth-child(2) {
      flex: 1 1 auto;
      background-color: white;
      table {
        height: 100%;
      }
    }
  }
}

.product-form {
  padding-top: 40px;
  .MuiPaper-root {
    padding: 0rem;
    h2 {
      border-radius: 4px 4px 0 0;
      padding: 1rem 2rem;
      margin-top: 0px;
      margin-bottom: 0px;
      background: linear-gradient(315deg, #eea849, #e55a00);
      > span {
        //font-family: "roboto_condensedbold";
        color: white;
      }
    }
    .MuiGrid-container {
      padding: 2rem;
    }
  }

  .MuiPaper-root:after {
    filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 13px;
    content: ' ';
    display: block;
    height: 10px;
    left: 25px;
    position: relative;
    transform: rotate(-3deg);
    width: 70%;
    z-index: -2;
  }
  .MuiAlert-standard:after {
    background-color: rgba(0,0,0,0);
  }
}

.searchUser {
  .MuiFormControl-root {
    padding-bottom: 2rem;
    flex-direction: row;
    justify-content: space-between;
    .MuiInputLabel-root {
      z-index: 2;
      color: @brand-primary;
    }
    .MuiFormLabel-root {
      color: @brand-primary;
      font-size: .875rem;
      line-height: 1.5rem;
    }
    .MuiInput-root {
      .MuiInputAdornment-root {
        .MuiSvgIcon-root {
          color: @brand-primary;
        }
      }
    }
  }
}

.userDialog {
  .MuiDialog-container {
    h2 {
      color: white;
      background-color: @brand-primary;
      margin-bottom: .5rem;
      box-shadow: 0 0 3px rgba(0,0,0,.8);
    }
    .MuiDialogContent-root {
        .MuiFormControl-root {
          margin-top: .5rem;
          margin-bottom: .5rem;

          &.select {
            margin-top: 1rem;
            margin-bottom: 1rem;
          }

          &.switch {
            display: flex;
            flex-direction: row;

            label {
              margin-left: .5rem;
              line-height: 1.75rem;
            }
          }

          .MuiFormHelperText-root {
            margin-left: 0px;
            color: @brand-primary;
          }
        }
      border-bottom: 1px solid #cbcbcb;
    }
    .MuiDialogActions-root {
      margin-top: 1rem;
      padding: 0 1rem 1rem 1rem;
    }
  }
}

.removeDialog {
  h2 {
    background-color: @brand-primary;
    color:white;
  }
  h6 {
    color: #c52845;
  }
  .MuiDialogContent-root {
    margin-top: 1rem;
  }
  .MuiDialogActions-root {
    border-top: 1px solid #e0e0e0;
  }
}

.MuiLinearProgress-root {
  z-index: 2 !important;
}

.MuiAvatar-img {
  width: 32px !important;
  height: 32px !important;
}

.games-navbar {
  .MuiButton-root {
    font-size: .75rem;
    border-radius: 2px;
    padding: 4px 12px;
  }
}

.datepicker {
  .MuiInputAdornment-positionStart {
    position: absolute;
    right: -6px;
  }
  .MuiInputAdornment-positionEnd {
    position: relative;
    right: 35px;
  }
}

