.product-list {
    td > .cover-image {
        max-height: 60px;
    }

    .product-list-row {
        &.readonly {
            opacity: 0.5;
        }
    }

    .product-list-row > td:first-child {
        width: 80px;
    }

    .option-col {
        position: relative;
        vertical-align: middle;
        .review-lights {
            margin: 5px 0 0 3px;
        }
    }
}
.product-edit {
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
}

