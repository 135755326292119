@import "variables.less";

@label-height: 22px;

.icon-label {
    height: @label-height;
    font-weight: 300;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin: 0;
    background-color: #F3F2F2;
    color: #8E8581;
    .required-postfix {
        color: @brand-primary;
    }
    .text {
        padding-left: 8px;
        line-height: 1;
    }
    .icon {
        background: #C7C5C4;
        width: @label-height;
        height: @label-height;
        font-size: @label-height;
        color: white;
        &.review {
            &.ok {
                background: @info-color5;
            }
            &.notok {
                background: @info-color7;
            }
            &.changed {
                background: @info-color3;
            }
        }
    }

}
